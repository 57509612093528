exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certificates-en-js": () => import("./../../../src/pages/certificates.en.js" /* webpackChunkName: "component---src-pages-certificates-en-js" */),
  "component---src-pages-company-en-js": () => import("./../../../src/pages/company.en.js" /* webpackChunkName: "component---src-pages-company-en-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-fr-js": () => import("./../../../src/pages/contact.fr.js" /* webpackChunkName: "component---src-pages-contact-fr-js" */),
  "component---src-pages-datenschutz-de-js": () => import("./../../../src/pages/datenschutz.de.js" /* webpackChunkName: "component---src-pages-datenschutz-de-js" */),
  "component---src-pages-haftungsausschluss-de-js": () => import("./../../../src/pages/haftungsausschluss.de.js" /* webpackChunkName: "component---src-pages-haftungsausschluss-de-js" */),
  "component---src-pages-impressum-de-js": () => import("./../../../src/pages/impressum.de.js" /* webpackChunkName: "component---src-pages-impressum-de-js" */),
  "component---src-pages-imprint-en-js": () => import("./../../../src/pages/imprint.en.js" /* webpackChunkName: "component---src-pages-imprint-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-kontakt-de-js": () => import("./../../../src/pages/kontakt.de.js" /* webpackChunkName: "component---src-pages-kontakt-de-js" */),
  "component---src-pages-leistungen-de-js": () => import("./../../../src/pages/leistungen.de.js" /* webpackChunkName: "component---src-pages-leistungen-de-js" */),
  "component---src-pages-leistungen-e-mobilitaet-de-js": () => import("./../../../src/pages/leistungen/e-mobilitaet.de.js" /* webpackChunkName: "component---src-pages-leistungen-e-mobilitaet-de-js" */),
  "component---src-pages-leistungen-fas-de-js": () => import("./../../../src/pages/leistungen/fas.de.js" /* webpackChunkName: "component---src-pages-leistungen-fas-de-js" */),
  "component---src-pages-leistungen-komfortelektronik-de-js": () => import("./../../../src/pages/leistungen/komfortelektronik.de.js" /* webpackChunkName: "component---src-pages-leistungen-komfortelektronik-de-js" */),
  "component---src-pages-mentions-legales-fr-js": () => import("./../../../src/pages/mentions-legales.fr.js" /* webpackChunkName: "component---src-pages-mentions-legales-fr-js" */),
  "component---src-pages-societe-fr-js": () => import("./../../../src/pages/societe.fr.js" /* webpackChunkName: "component---src-pages-societe-fr-js" */),
  "component---src-pages-unternehmen-de-js": () => import("./../../../src/pages/unternehmen.de.js" /* webpackChunkName: "component---src-pages-unternehmen-de-js" */),
  "component---src-pages-zertifikate-de-js": () => import("./../../../src/pages/zertifikate.de.js" /* webpackChunkName: "component---src-pages-zertifikate-de-js" */),
  "component---src-templates-aktuelles-de-js": () => import("./../../../src/templates/aktuelles.de.js" /* webpackChunkName: "component---src-templates-aktuelles-de-js" */),
  "component---src-templates-article-en-js": () => import("./../../../src/templates/article.en.js" /* webpackChunkName: "component---src-templates-article-en-js" */),
  "component---src-templates-artikel-de-js": () => import("./../../../src/templates/artikel.de.js" /* webpackChunkName: "component---src-templates-artikel-de-js" */),
  "component---src-templates-news-en-js": () => import("./../../../src/templates/news.en.js" /* webpackChunkName: "component---src-templates-news-en-js" */)
}

